<template>

    <div class="loader" v-if="loading" v-loading="loading"></div>

    <template v-if="page">
        <teleport to="head">
            <title>{{ page['title_'+prefs.lang] }} – PLANTA</title>
            <meta name="description" :content="page['desc_'+prefs.lang]">
        </teleport>

        <teleport to="#top_benefit">
            <div class="benefit_area ba_page">
              <div class="ba_pg">
                <h1>{{ page['title_'+prefs.lang] }}</h1>
                <h3 v-if="page.id != 3">{{ page['desc_'+prefs.lang] }}</h3>
<!--                <h3 v-if="page.id != 3" v-html="page['desc_'+prefs.lang]">{{ page['desc_'+prefs.lang] }}</h3>-->
              </div>
            </div>
        </teleport>

        <div v-html="page['text_'+prefs.lang]" id="art_page"></div>

    </template>

    <template v-else>
        <teleport to="head">
            <title>{{ txt[prefs.lang].not_found }}</title>
        </teleport>

        <h1 class="big">{{ txt[prefs.lang].not_found }}</h1>
        <div class="centered">
            <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].products }}</el-button></router-link>
        </div>

    </template>

</template>

<script>
import useCart from "@/use/cart"
import { txt } from '@/use/txt'
import {useQuery, useResult} from '@vue/apollo-composable'
import { gql } from '@apollo/client'
import { ref, watch, onUpdated } from 'vue'
import pageQuery from '../graphql/page.gql'
import router from '@/router/index'

export default {
    setup(props) {

        const { prefs } = useCart()

        onUpdated(() => {
            refetch({ url: props.url })
        })

        const { result, loading, error, refetch } = useQuery(pageQuery, { url: props.url })

        const page = useResult(result, null, data => data.page)

        return { prefs, page, loading, error, txt }
    },
    props: {
        url: String
    }
}
</script>

<style>
#art_page {
  margin: 0 auto;
  padding: 20px 20px 0;
  max-width: 900px;
  font-size: 1.2rem;
  letter-spacing: normal;
  /*letter-spacing: 1px;*/
  line-height: 150%;
}
#art_page p {
  margin-top: 1.5em;
}

.ba_pg {
  max-width: 900px;
  margin: 0 auto;
}

#art_page h1, #art_page h2, #art_page h3, #art_page h4 {
  margin-top: 3rem;
}

blockquote {
  font-style: italic;
  color: #888;
  /*margin-top: 2em;*/
  border-left:5px solid #eee; padding-left:1.5em; margin:2em 0;
}
/*blockquote small {display:block; line-height:1.7em;}*/

/*ul, ol, dl {*/
/*  text-align:left;*/
/*}*/
#art_page ul li {
  margin-left: 3rem;
  /*list-style: "✔︎ ";*/
  list-style: disc;
}
sup {
  color: #9b9;
}
</style>